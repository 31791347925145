import { ResponsiveBar } from "@nivo/bar";
import React, { FunctionComponent } from "react";

export interface CostTrendChartProps {
  data?: { month: string; amount: number }[];
}

export const CostTrendChart: FunctionComponent<CostTrendChartProps> = ({
  data,
}) => {
  return (
    <ResponsiveBar
      tooltipFormat={(value) =>
        `$${Number(value).toLocaleString("en-US", {
          minimumFractionDigits: 2,
        })}`
      }
      data={data as any}
      keys={["amount"]}
      indexBy="month"
      margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
      colors="#00bceb"
      defs={[]}
      fill={[]}
      borderColor={{ from: "color", modifiers: [["darker", 1.4]] }}
      axisTop={null}
      axisLeft={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Cost Trend",
        legendPosition: "middle",
        legendOffset: 35,
      }}
      axisRight={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "",
        legendPosition: "middle",
        legendOffset: -40,
        format: "$.2f",
      }}
      enableLabel={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      legends={[]}
    />
  );
};

export default CostTrendChart
