import { ResponsivePie } from "@nivo/pie";
import React from "react";

export interface AllTrackedCostsChartProps {
  data: {
    id: string;
    label: string;
    value: number;
    color: string;
  }[];
}

export const AllTrackedCostsChart: React.FC<AllTrackedCostsChartProps> = ({
  data,
}) => {
  return (
    <ResponsivePie
      data={data}
      innerRadius={0.8}
      padAngle={0.7}
      cornerRadius={3}
      colors={(d) => d.color as string}
      borderWidth={1}
      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
      radialLabelsSkipAngle={10}
      radialLabelsTextXOffset={6}
      radialLabelsTextColor="#333333"
      radialLabelsLinkOffset={0}
      radialLabelsLinkDiagonalLength={16}
      radialLabelsLinkHorizontalLength={24}
      radialLabelsLinkStrokeWidth={1}
      radialLabelsLinkColor={{ from: "color" }}
      slicesLabelsSkipAngle={10}
      slicesLabelsTextColor="#333333"
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      enableRadialLabels={false}
      enableSlicesLabels={false}
      legends={[]}
    />
  );
};

export default AllTrackedCostsChart;
