import { TableSortLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import React, { FunctionComponent } from "react";

type Order = "asc" | "desc";

interface Column {
  id:
    | "paymentDate"
    | "invoiceID"
    | "paymentInstrument"
    | "transactionType"
    | "paymentMethod"
    | "transactionAmount";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
  sort: (a: IData, b: IData) => number;
}

const columns: Column[] = [
  {
    id: "paymentDate",
    label: "Payment Date",
    minWidth: 170,
    sort: (a: IData, b: IData) =>
      new Date(a.paymentDate).getTime() - new Date(b.paymentDate).getTime(),
  },
  {
    id: "invoiceID",
    label: "Invoice ID",
    minWidth: 100,
    sort: (a: IData, b: IData) => parseInt(a.invoiceID) - parseInt(b.invoiceID),
  },
  {
    id: "paymentInstrument",
    label: "Payment Instrument",
    minWidth: 170,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
    sort: (a: IData, b: IData) => {
      const aDigits = a.paymentInstrument.match(/\d+/)?.[0];
      const bDigits = b.paymentInstrument.match(/\d+/)?.[0];
      return aDigits !== undefined && bDigits !== undefined
        ? aDigits.localeCompare(bDigits)
        : 0;
    },
  },
  {
    id: "transactionType",
    label: "Transaction Type",
    minWidth: 170,
    align: "right",
    format: (value: number) => value.toLocaleString("en-US"),
    sort: (a: IData, b: IData) =>
      a.transactionType.localeCompare(b.transactionType),
  },
  {
    id: "paymentMethod",
    label: "Payment Method",
    minWidth: 170,
    align: "right",
    sort: (a: IData, b: IData) =>
      a.paymentMethod.localeCompare(b.paymentMethod),
  },
  {
    id: "transactionAmount",
    label: "Transaction Amount",
    minWidth: 170,
    align: "right",
    format: (value: number) => value.toFixed(2),
    sort: (a: IData, b: IData) => a.transactionAmount - b.transactionAmount,
  },
];

export interface IData {
  paymentDate: string;
  invoiceID: string;
  paymentInstrument: string;
  transactionType: string;
  paymentMethod: string;
  transactionAmount: number;
}

function randomDate(start: any, end: any) {
  return new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  );
}

function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

const rows = new Array(30).fill(null).map(() => ({
  paymentDate: moment(randomDate(new Date(2012, 0, 1), new Date())).format(
    "YYYY-MM-DD"
  ),
  invoiceID: getRandomInt(1000000, 9999999).toString(),
  paymentInstrument: `Ending in ${getRandomInt(1000, 9999)}`,
  transactionType: "Charge",
  paymentMethod: "Credit Card",
  transactionAmount: Number(getRandomInt(10, 399).toFixed(2)),
}));

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
    width: "100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
});

// @ts-ignore
export interface InvoicesTableProps {}

export const InvoicesTable: FunctionComponent<InvoicesTableProps> = () => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<Column["id"]>("paymentDate");
  const sortRoutine = columns.find((c) => c.id === orderBy)?.sort;
  if (!sortRoutine) {
    throw new Error("sortRoutine not found");
  }

  const handleRequestSort = (property: Column["id"]) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={() => handleRequestSort(column.id)}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .sort((a: IData, b: IData) =>
                order === "asc" ? sortRoutine(a, b) : -sortRoutine(a, b)
              )
              .map((row, i) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={`${row.invoiceID}-${i}`}
                >
                  {columns.map((column, idx) => {
                    const value = row[column.id];
                    const link = (
                      <a
                        href="https://ignw-test.chargebee.com/d/invoices/62"
                        target="_blank"
                      >
                        {value}
                      </a>
                    );
                    const v =
                      column.format && typeof value === "number"
                        ? column.format(value)
                        : value;
                    return (
                      <TableCell
                        key={`${column.id}-${i}-${idx}`}
                        align={column.align}
                      >
                        {column.id === "invoiceID" ? link : v}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

export default InvoicesTable;
