import React from "react";
import { useTranslation } from "react-i18next";
import Billing from "../components/billing/Billing";
import Layout from "../components/layout/Layout";
import SEO from "../components/seo/SEO";

const BillingPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO title={t("billing.title")} />
      <Billing />
    </Layout>
  );
};

export default BillingPage;
